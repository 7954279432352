import React from "react";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  decremented,
  incremented,
  initializeScreen,
} from "@/lib/features/slices/screensSlice";
import {
  useHandleFormRegAPI,
  useHandleLoginVerify,
  useHandleRegisterForm,
} from "@/lib/features/slices/registrationFormSlice";
import { useHandlePaymentMethod } from "@/lib/features/slices/paymentMethodSlice";
import { setOTPError, setOTPValue } from "@/lib/features/slices/phoneSlice";
import { FooterProps } from "@/lib/types/types";
import { showModal } from "@/lib/features/slices/paymentSlice";
import toast from "react-hot-toast";

const Footer: React.FC<FooterProps> = ({
  singleBtnLight,
  singleBtnDark,
  rightBtnText,
  leftBtnText,
  handleSubmit,
}) => {
  const dispatch = useAppDispatch();
  const handleRegisterForm = useHandleRegisterForm();
  const handleFormRegAPI = useHandleFormRegAPI();
  const handleLoginVerify = useHandleLoginVerify();
  const handlePaymentMethod = useHandlePaymentMethod();
  const { screenId } = useAppSelector((state) => state.screenDetails);
  const { userPhase } = useAppSelector((state) => state.phoneFormScreen);
  const { selectedMethod } = useAppSelector((state) => state.paymentMethod);

  const handleCheckScreenValidation = async () => {
    switch (screenId) {
      case 2:
        handleLoginVerify();
        break;
      case 3:
        handleRegisterForm();
        break;
      case 4:
        handlePaymentMethod();
        break;
      case 5:
        selectedMethod !== ""
          ? dispatch(showModal(true))
          : toast.error("No Payment Method Selected");
        break;
      case 6:
        dispatch(incremented());
        break;
      default:
        dispatch(incremented());
        break;
    }
  };

  // const handleBackBtn = async () => {
  //   dispatch(setOTPValue({ name: "code", value: "" }));
  //   dispatch(setOTPError({ name: "codeError", error: false }));
  //   if (screenId === 3 && userPhase == "registerPhase") {
  //     dispatch(decremented());
  //   } else if (screenId === 3 && userPhase == "loginPhase") {
  //     dispatch(initializeScreen(1));
  //   } else {
  //     dispatch(decremented());
  //   }
  // };

  return (
    <>
      {singleBtnLight && (
        <div className="footer">
          <button
            onClick={handleCheckScreenValidation}
            className="footer-single-btn-light"
          >
            <p>{singleBtnLight}</p>
          </button>
        </div>
      )}
      {singleBtnDark && (
        <div className="footer">
          <button
            onClick={handleCheckScreenValidation}
            className="footer-single-btn-dark"
          >
            <p>{singleBtnDark}</p>
          </button>
        </div>
      )}

      {leftBtnText && (
        <div className="footer-two-btn mt-10">
          <button
            onClick={() => dispatch(decremented())}
            className="footer-left-btn"
          >
            <div>{leftBtnText}</div>
          </button>

          <button
            onClick={handleCheckScreenValidation}
            className="footer-right-btn"
          >
            <div>{rightBtnText}</div>
          </button>
        </div>
      )}
    </>
  );
};

export default Footer;
