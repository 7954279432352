import { RegFormQueryData } from "@/lib/types/types";
import { apiClient } from "../utils/apiClient";

export const register = (queryData: RegFormQueryData) => {
  return apiClient.post(`/register`, queryData);
};
export const loginVerify = (queryData: { phone: string; code: string }) => {
  return apiClient.post(`/login/verify?platform=web`, queryData);
};
export const paymentMethod = (accessToken: string) => {
  return apiClient.post(`/payments`, {}, accessToken);
};
// export const payment = (paymentId: number, accessToken: string) => {
//   return apiClient.post(`/payments/${paymentId}`, {}, accessToken);
// };
export const login = (queryData: { phone: string }) => {
  return apiClient.post(`/login?platform=web`, queryData);
};
export const requestOTP = (queryData: { phone: string }) => {
  return apiClient.post(`/otp/sms`, queryData);
};
export const getInvitation = (id: string | string[] | undefined) => {
  return apiClient.get(`/invitations/${id}`);
};
export const acceptInvitation = (id: string | string[] | undefined) => {
  return apiClient.post(`/invitations/${id}/accept`);
};
